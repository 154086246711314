import $ from 'jquery'

document.addEventListener("turbolinks:load", function() {
  $(document).on('change', 'select#staff_type', function() {
    var personsList = $('select.salaryOfPersonList');
    if($(this).val() != '') {
      $.ajax({
        type: "GET",
        url: '/portal/salaries/persons_list',
        data: {staff_type: $(this).val()},
        dataType: "json",
        success: function(result) {
          var personsList = $('select.salaryOfPersonList');
          if(personsList[0].form.action.includes('advance_return')) {
            $('input#advance_amount').val('0');
            $('input#ledger_book_debit').val('0');
          } else {
            $('div.salaryField').addClass('d-none');
          }
          var salaryOf = $('select#staff_type').val();
          salaryOf = (salaryOf == 'Teacher') ? 'Teacher' : 'Other Staff'
          personsList.parent().find('span').text(salaryOf);
          personsList.children().remove();
          personsList.append('<option value="">Please Select ' + salaryOf + '</option>');
          for(var i=0; i<result.length; i++) {
            var person = result[i];
            personsList.append('<option value="'+ person[1] +'">' + person[0] + '</option>');
          }
        }
      })
    } else {
      personsList.parent().find('span').text('Person');
      $('select.salaryOfPersonList').val('');
      $('select.salaryOfPersonList').children().remove();
			$('select.salaryOfPersonList').append('<option value="">Please Select Person</option>');

      if(personsList[0].form.action.includes('advance_return')) {
        $('input#advance_amount').val('0');
        $('input#ledger_book_debit').val('0');
      } else {
        $('div.salaryField').addClass('d-none');
      }
    }
  })

  $(document).on('change', 'select.salaryOfPersonList', function(){
    if($(this).val() != '') {
      var salaryType = $('input#ledger_book_type').val();
      var staffID = $(this).val();
      $.ajax({
        type: "GET",
        url: '/portal/salaries/salary_details',
        data: { ledger_book: {
          staff_id: staffID, type: salaryType
        }},
        dataType: "json",
        success: function(result) {
          var personsList = $('select.salaryOfPersonList');
          if(personsList[0].form.action.includes('advance_return')) {
            var dueAdvance = (result.person.advance_amount || 0);
            $('input#ledger_book_debit').val('0');
            $('input#advance_amount').val(dueAdvance);
          } else {
            var monthly_salary = (result.person.monthly_salary || 0);
            var dueAdvance = (result.person.advance_amount || 0);
            var perDaySalary = (result.per_day_salary || 0);

            if($('input#ledger_book_type').val() == 'Advance') {
              $('input#advance_amount').val(dueAdvance);
            } else {
              $('input#actual_salary').val(monthly_salary);
              $('input#ledger_book_salary_detail_attributes_per_day_salary').val(perDaySalary);
              $('input#advance_amount').val(dueAdvance);
              $('input#ledger_book_salary_detail_attributes_paid_amount').val(monthly_salary);
              $('input#ledger_book_salary_detail_attributes_paid_amount').attr('max', monthly_salary);
              $('input#ledger_book_salary_detail_attributes_advance_deduction').attr('max', dueAdvance);
            }
            $('div.salaryField').removeClass('d-none');
          }
        }
      })
    } else {
      if(personsList[0].form.action.includes('advance_return')) {
        $('input#advance_amount').val('0');
        $('input#ledger_book_debit').val('0');
      } else {
        $('div.salaryField').addClass('d-none');
      }
    }
  })

  $(document).on('keyup', 'input#ledger_book_salary_detail_attributes_advance_deduction', function() {
    var advanceDeduction = parseFloat($(this).val() || '0');
    var advanceAmount = parseFloat($('input#advance_amount').val() || '0');
    var leavesDeductions = parseFloat($('input#ledger_book_salary_detail_attributes_leave_deductions').val() || '0');
    var salary = parseFloat($('input#actual_salary').val() || '0');
    if(advanceDeduction > advanceAmount) {
      $(this).val(advanceAmount);
    }
    var advanceDeduction = parseFloat($(this).val() || '0');
    var totalDeductions = (advanceDeduction + leavesDeductions);
    $('input#ledger_book_salary_detail_attributes_total_deduction').val(totalDeductions);
    var salaryAfterDeductions = (salary - totalDeductions);
    $('input#ledger_book_salary_detail_attributes_paid_amount').val(salaryAfterDeductions);
  })

  $(document).on('change', 'input#ledger_book_salary_detail_attributes_leaves', function() {
    var noOfLeaves = parseFloat($(this).val());
    $(this).val(noOfLeaves || '0');
    var noOfLeaves = parseFloat($(this).val());
    var advanceDeduction = parseFloat($('input#ledger_book_salary_detail_attributes_advance_deduction').val() || '0');
    var salary = parseFloat($('input#actual_salary').val() || '0');
    var perDaySalary = parseFloat($('input#ledger_book_salary_detail_attributes_per_day_salary').val() || '0');
    var leavesDeductions = noOfLeaves * perDaySalary
    $('input#ledger_book_salary_detail_attributes_leave_deductions').val(leavesDeductions);
    var deductions = (advanceDeduction + leavesDeductions);
    $('input#ledger_book_salary_detail_attributes_total_deduction').val(deductions);
    var salaryAfterDeductions = (salary - deductions);
    $('input#ledger_book_salary_detail_attributes_paid_amount').val(salaryAfterDeductions);
  })

  $(document).on('keyup', '#ledger_book_debit', function() {
    if(this.form.action.includes('advance_returns')) {
      var paidAmount = parseFloat($('input#ledger_book_debit').val() || '0');
      var advanceAmount = parseFloat($('input#advance_amount').val() || '0');
      if(paidAmount > advanceAmount) {
        $('input#ledger_book_debit').val(advanceAmount);
      }
    }
  })

  // $('button.addDeduction').on('click', function () {
  //   leavesDeductions = parseFloat($('input#ledger_book_salary_detail_attributes_leave_deductions').val() || '0');
  //   advanceAmount = parseFloat($('input#advance_amount').val() || '0');
  //   totalDeductions = (advanceAmount + leavesDeductions);
  //   salary = parseFloat($('input#actual_salary').val());
  //   $('input#ledger_book_salary_detail_attributes_advance_deduction').val(advanceAmount);
  //   $('input#ledger_book_salary_detail_attributes_total_deduction').val(totalDeductions);
  //   $('input#ledger_book_salary_detail_attributes_paid_amount').val(salary - totalDeductions);

  // })

  // $('button.removeDeduction').on('click', function () {
  //   leavesDeductions = parseFloat($('input#ledger_book_salary_detail_attributes_leave_deductions').val() || '0');
  //   salary = parseFloat($('input#actual_salary').val());
  //   $('input#ledger_book_salary_detail_attributes_total_deduction').val(leavesDeductions);
  //   $('input#ledger_book_salary_detail_attributes_paid_amount').val(salary - leavesDeductions);
  //   $('input#ledger_book_salary_detail_attributes_advance_deduction').val('0');
  // })
})
