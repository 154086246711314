import $, { event } from 'jquery'

document.addEventListener("turbolinks:load", function() {
  $(document).on('keyup', 'input.fee_charge_field', function() {
    var errorMessage = 'You are not able to submit fee.<br>Please enter a valid amount';
    var balanceText = $(this.form).find('p.balance').text();
    validate_charge_form(this, balanceText, errorMessage);
  })

  $(document).on('keyup', 'input.fee_paid_amount_field', function() {
    var errorMessage = 'You are not able to pay fee.<br>Please enter a valid amount';
    var balanceText = $(this.form).find('p.balance').text();
    validate_charge_form(this, balanceText, errorMessage);
  })

  $(document).on('keyup', 'input.fee_concession_field', function() {
    var errorMessage = 'You are not able to add concession fee.<br>Please enter a valid amount';
    var balanceText = $(this.form).find('p.balance').text();
    validate_charge_form(this, balanceText, errorMessage);
  })

  function validate_charge_form(amountField, balanceText, errorMessage){
    $(amountField).parent().find('span').remove();
    $(amountField.form).find('div.error').children().remove();
    $(amountField.form).find('input[type=submit]').removeAttr('disabled');
    var canSubmit = true;
    if($(amountField).val()) {
      var enteredAmount = parseInt($(amountField).val());
      var balanceAmount = parseInt(balanceText);
      if(enteredAmount > balanceAmount) {
        $(amountField).parent().append("<span class='text-danger fs-10'>Amount must be less then or equal to " + balanceText + "</span>");
      }

      var fields = $(amountField.form).find('input[type=number]');
      for(var i = 0; i < fields.length; i++) {
        if($(fields[i]).parent().find('span').length != 0) {
          canSubmit = false;
        }
      }
      if(canSubmit == false) {
        $(amountField.form).find('div.error').append("<span class='text-danger fs-15 mb-2'>" + errorMessage + "</span>");
        $(amountField.form).find('input[type=submit]').attr('disabled', true);
      }
    }
  }
})
